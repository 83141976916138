body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
   /* font-family: 'Poppins', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  /* font-family: 'Noto Sans', sans-serif; */
  background-color: #000522;
}

@import "~react-image-gallery/styles/css/image-gallery.css";
@font-face {
  font-family: 'gok_stencilregular';
  src: url('./fonts/gok_stencil-webfont.woff2') format('woff2'),
       url('./fonts/gok_stencil-webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;

}

html {
  scroll-behavior: smooth;
}

.TextSchedule {
  font-family: 'gok_stencilregular', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ButtonTeste {
  width: 200px;
  height: 200px
}

img {
  width: 100%;
  height: auto
}

.SliderBanner1 {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 0px;
}

.slick-prev {
  left: -40px !important;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#Parceiros {
  margin-top: -26vw;
  position: absolute;
}

.IframeCommercial {
  width: 65%;
  height: 29vw;
}

@media (max-width: 1230px) {
  .IframeCommercial {
    width: 57%;
    height: 25vw;
}
}

@media (max-width: 1000px) {
  .IframeCommercial {
    width: 100%;
    height: 43vw;
    margin-top: 35px;
}
}


@media (max-width: 985px) {
  .slick-list {
    margin-right: -14px;
}
}


@media (max-width: 900px) {
  .SliderBanner1 {
    width: 90%;
  }
}

@media (max-width: 700px) {
  .slick-next {
    right: -39px !important;
  }
}

@media (max-width: 500px) {
  .IframeCommercial {
    height: 48vw;
  }

  .slick-next {
    right: -32px !important;
  }

  .slick-prev {
    left: -30px !important;
}
}


.IframeShow {
  border: 0px;
  width: 62%;
}

.IframeShow2Resp {
  display: none;
}

.IframeMercedes {
  height: 420px;
}

.IframeEventos {
  height: 28vw;
}

.IframeEvents {
  height: 62vh;
}

.FormContato {
  display: flex;
  flex-direction: column;
}

.InputForm {
  border: 2px solid #446fe7;
  background-color: transparent;
  margin-top: 15px;
  height: 50px;
  border-radius: 29px;
  padding-left: 4%;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.InputTextArea {
  border: 2px solid #446fe7;
  background-color: transparent;
  margin-top: 15px;
  height: 188px;
  border-radius: 29px;
  padding-left: 4%;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding-top: 12px;
}

.BotaoEnvia {
  width: 250px;
  height: 55px;
  background-color: #446fe7;
  border-radius: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  border: 0px;
  cursor: pointer;
}

.AlertaJs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 246px;
  background-color: #070f26;
  justify-content: space-around;
  border-radius: 13px;
}

.BotaoAcessarBD {
  background-color: #446fe7;
  width: 250px;
  height: 41px;
  border: 0px;
  border-radius: 12px;
  font-family: 'Poppins';
  font-weight: bold;
  color: #fff;
  font-size: 19px;
  cursor: pointer;
}


::-webkit-scrollbar-track {
  background-color: #131d3a;
  margin-left: 40px;
  border-radius: 12px;
}
::-webkit-scrollbar {
  width: 6px;
  background: #F4F4F4;
  border-radius: 12px;
}
::-webkit-scrollbar-thumb {
  background: #446fe7;
  border-radius: 12px;
}




@media (max-width: 1650px) {
  .IframeMercedes {
    height: 28vw;
  }

  .IframeEventos {
    height: 30vw;
  }
}

@media (max-width: 1340px) {
  .BotaoEnvia {
    width: 185px;
  }
}

@media (max-width: 1180px) {
  .BotaoEnvia {
    width: 300px;
    margin-top: 15px;
  }
}

@media (max-width: 900px) {
  .IframeMercedes {
    height: 50vw;
  }
}


@media (max-width: 850px) {
  .IframeShow {
    width: 100%;
    height: 48vw;
    margin-top: 30px;
  }

  .IframeShow2Desk {
    display: none;
  }

  .IframeShow2Resp {
    display: block;
  }

  .IframeEventos {
    height: 48vw;
  }
}